.newbackground {
  background-image: linear-gradient(
      94.5deg,
      rgba(0, 80, 118, 0.15) 0%,
      rgba(206, 39, 89, 0.15) 100%
    ),
    url("../../assets/images/filtermain3.jpg") !important;
  h1 {
    padding-top: 50px;
    font-weight: 400 !important;
  }
  .background_red {
    text-transform: capitalize;
  }
}
.website {
  background-image: linear-gradient(
      94.5deg,
      rgba(0, 80, 118, 0.15) 0%,
      rgba(206, 39, 89, 0.15) 100%
    ),
    url("../../assets/images/website.jpg") !important;
}
.growth {
  background-image: linear-gradient(
      94.5deg,
      rgba(0, 80, 118, 0.15) 0%,
      rgba(206, 39, 89, 0.15) 100%
    ),
    url("../../assets/images/growth.jpg") !important;
}
.managing-my-business {
  background-image: linear-gradient(
      94.5deg,
      rgba(0, 80, 118, 0.15) 0%,
      rgba(206, 39, 89, 0.15) 100%
    ),
    url("../../assets/images/manage-my-business.jpg") !important;
}
.accounting {
  background-image: linear-gradient(
      94.5deg,
      rgba(0, 80, 118, 0.15) 0%,
      rgba(206, 39, 89, 0.15) 100%
    ),
    url("../../assets/images/accounting.jpg") !important;
}
.directory_parent {
  display: flex;
  flex-direction: row;
}

.filtermain {
  min-height: 630px;
  background-image: linear-gradient(
      94.5deg,
      rgba(0, 80, 118, 0.3) 0%,
      rgba(206, 39, 89, 0.3) 100%
    ),
    url("../../assets/images/filtermain.png");
  background-size: cover;
  background-repeat: no-repeat;
  .gov_logo {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    img {
      width: 20vw;
      max-width: 100px;
      min-width: 100px;
      padding-top: 5px;
    }
    span {
      font-size: 13px;
    }
  }
  h1,
  h1 > span {
    font-weight: 700;
    font-size: 60px;
    margin-top: 15px;
    margin-bottom: 20px;
    color: white;
  }
  span {
    font-size: 20px;
    color: white;
    line-height: initial;
    margin: 0;
    font-weight: 500;
  }
}

#results_page,
#site_page {
  .filtermain {
    min-height: 500px;
  }
}

.filtermain .flextyperow {
  .table_center:nth-child(1) {
    padding-right: 20px;
  }
}
@media (max-width: 1300px) {
  .filtermain h1,
  .filtermain_old h1,
  .filtermain h1 .background_gradient,
  .background_red > h1 {
    font-size: 50px !important;
  }
  .filtermain .gov_logo {
    img {
      width: 20vw;
    }
  }
  .directory_parent {
    padding-bottom: 20px;
  }
  #directory_container {
    flex-direction: column;
  }
}

@media (max-width: 750px) {
  .drop-down__button {
    min-width: 230px !important;
    width: 197px !important;
  }
  .filtermain h1,
  .filtermain_old h1,
  .filtermain h1 .background_gradient {
    font-size: 7vw !important;
  }
  .directory_parent {
    flex-direction: column;
    justify-content: center;
  }
  .filtermain .flextyperow .table_center:nth-child(1) {
    padding-right: 0px;
    padding-bottom: 20px;
  }
  .filtermain .gov_logo {
    img {
      padding-top: 5px;
    }
  }
  .newbackground {
    background-position: top;
    span {
      font-size: 35px !important;
    }
    h1 {
      font-size: 40px !important;
      padding: 0px 20px;
    }
  }
}

@media (max-width: 450px) {
  .drop-down__button {
    min-width: 192px !important;
    width: 197px !important;
  }
  .filtermain h1,
  .filtermain_old h1,
  .filtermain h1 .background_gradient {
    font-size: 28px !important;
    overflow-wrap: anywhere;
  }
  .filtermain .gov_logo {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .filtermain span,
  .drop-down__name,
  .controller_list,
  .drop-down__item {
    font-size: 15px !important;
  }
  .newbackground {
    span {
      font-size: 30px !important;
    }
    h1 {
      display: flex;
      flex-direction: column;
      line-height: 17px;
      font-size: 35px !important;
      padding: 0px 20px;
    }
  }
  .filtermain_filter_container {
    bottom: 100px !important;
  }
}
