$lightBlue: #7bd5ff;
$regBlue: #0076ad;
$darkBlue: #005076;
$lightGrey: #f4f4f4;
$lightGrey: #f4f4f4;
$superlightGrey: #f9f9f9;
$superlightGrey_bkd: #f9f9f9;
$green: #5eb22e;
$darkGeneral: #333333;
$darkGrey: #656565;
$red: #ce2759;
@use "./assets/css/_variables";
@use "./assets/css/_mixins";

@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;700;800;900&display=swap");

@keyframes fadeIn {
  from {
    opacity: 0;
    margin-top: 10px;
  }

  to {
    opacity: 1;
    margin-top: 0px;
  }
}

.landing_3 {
  .item_toolbox {
    .toolbox_app_container {
      height: 40px;
    }

    img {
      max-width: 128px;
      max-height: 43px;
      width: auto;
      height: auto;
      margin-right: 20px;
    }
  }
}

.logocase {
  height: 43px;
}

.gc2.profile {
  padding-bottom: 10px;

  a .blue {
    font-weight: 500;
  }
}

.hr_parent:first-child {
  display: none;
}

hr {
  border-top: $lightGrey;
  margin: 20px 0px;
}

.specialoffercontent {
  padding-top: 40px;
  padding-bottom: 75px;
}

.casestudylisting.background_dgrey {
  box-shadow: none;
  padding: 50px !important;
  text-align: center;

  h4 {
    margin: 0px;
  }
}

.casestudylisting {
  display: flex;
  flex-direction: column;
  padding: 0px !important;

  .listingWrapper {
    padding: 30px;

    img {
      width: auto;
      margin-right: 20px;
      height: auto;
      max-height: 42px;
      max-width: 173px;
    }
  }

  .listing_p {
    padding: 20px 0px;
  }
}

#digitalboost {
  .profile {
    padding: 25px 0px 25px 0px;
  }
}

.landing_4 {
  padding: 0px 50px 50px 50px;

  .btn_container {
    min-width: 275px;
    padding-top: 50px;
  }

  .main_p {
    max-width: 85%;
  }
}

.opacity3 {
  opacity: 0.3;
}

.opacity2 {
  opacity: 0.6;
}

.skiplink {
  padding: 8px;
  position: absolute !important;
  background: $darkBlue;
  left: 50%;
  height: 30px;
  opacity: 0;
  color: white !important;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 999999999;
}

.errorbox {
  font-size: 14px;
  margin-bottom: 20px;

  li {
    color: #333333;
    font-family: "Fira Sans", sans-serif;
    list-style: none;
    text-transform: capitalize;
    background-color: #ffa5a5;
    border-radius: 3px;
    padding: 8px;
    margin: 2px;
  }
}

.skiplink:focus {
  opacity: 1;
  transform: translateY(0%);
}

.starsnew {
  display: flex;
  align-items: center;

  .darkgrey {
    padding-left: 15px;
  }

  padding-bottom: 20px;
}

//global
.min-height {
  min-height: 100vh;
  // background-color: grey;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.ReactModal__Overlay {
  transition: all 200ms ease-in-out;
  opacity: 0;
  z-index: 99999 !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Content {
  grid-gap: 0px;
  opacity: 0;
  transform: translateX(-100px);
  transition: all 200ms ease-in-out;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateX(-100px);
}

.splash-spinner {
  animation: rotate 2s linear infinite;
  margin-top: 200px;
  width: 50px;
  height: 50px;
}

.splash-spinner .path {
  stroke: #5d78ff;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

.hide {
  display: none;
}

.splashscreen {
  min-height: 50vh;
  display: flex;
  justify-content: center;
}

.landing {
  .splashscreen {
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    position: absolute;
    top: 1;
    background-color: white;

    .splash-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.basket {
  transition: opacity 0.5s;
  opacity: 1;
}

.contact_Spinner {
  min-height: 50vh;
  display: flex;
  justify-content: center;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.center_all {
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.center_vert {
  justify-content: center;
  flex-direction: column;
}

.inline-block {
  display: inline-block;
}

.content {
  padding-top: 75px;
  padding-bottom: 75px;
}

.pointer {
  cursor: pointer;

  button {
    cursor: pointer;
  }
}

.strong_span {
  font-weight: bold;
}

.flex {
  display: flex;
}

.sub_span {
  color: $darkGrey;
}

.text_center {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &:first-letter {
    text-transform: uppercase;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
span a,
textarea {
  color: $darkGeneral;
  font-family: "Fira Sans", sans-serif;
}

a {
  font-family: "Fira Sans", sans-serif;
  text-decoration: none;
}

h2 {
  font-weight: bold;
  font-size: 35px;
}

h4 {
  font-size: 28px;
}

a,
li {
  text-decoration: none;

  //&:visited {
  //  color: inherit;
  //}
  //
  //& :focus {
  //  color: inherit;
  //}
}

p {
  color: $darkGeneral;
  font-size: 16px;
}

.main_p {
  font-size: 18px;
  line-height: 31px;
}

.white {
  color: white;
}

.background_grey {
  background-color: $lightGrey;
}

.background_supergrey {
  background-color: $superlightGrey;
}

.background_red {
  background-color: $red;
}

.background_darkred {
  background-color: #6b6b6b;
  cursor: default;
}

.background_blue {
  background-color: $regBlue;
}

.background_dgrey {
  background-color: #e1e1e1;
}

.background_dblue {
  background-color: #005076;
}

.background_gradient {
  background: linear-gradient(92.49deg, #0d4461d7 0%, #0078afe7 100%);
}

.background_light {
  background: rgba(0, 119, 175, 0.05);
}

.buffer {
  padding: 30px 0;
}

.background_green {
  background-color: $green;
}

.background_white {
  background-color: white;
}

.blue,
.blue a {
  color: $regBlue !important;
}

.red,
.red a {
  color: $red;
}

.dark,
.dark a {
  color: $darkGeneral !important;
}

.darkgrey {
  color: $darkGrey;
}

.empty {
  display: none;
}

.iconsBelow {
  padding-bottom: 50px;
}

#categories_main {
  .profile {
    padding-bottom: 50px;
  }
}

//landing page
.iconsBelow .flexrow,
.flextyperow {
  display: flex;
  flex-direction: row;
}

.stepsIcon {
  width: 165px;
}

.padding_h2 {
  margin: 0;
}

.imageCTA {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.italics {
  font-family: Fira Sans;
  font-style: italic;
  font-weight: normal;
}

.toolCTA {
  margin-top: 45px;
  border-radius: 5px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
}

.imageCTA_inner {
  &:nth-child(odd) {
    padding: 50px;
    grid-column: 1/7;
  }

  &:nth-child(even) {
    border-radius: 0px 5px 5px 0px;
    grid-column: 7/13;
  }
}

.toolkit_container {
  display: flex;
  justify-content: space-between;

  .company {
    background-color: $lightGrey;
    width: 30%;
    height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .category {
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
  }
}

#imageCTAcontainer {
  .content div {
    margin-top: 50px;
    margin-bottom: 25px;
  }
}

.company_inner {
  padding: 50px;
  text-align: center;

  img {
    height: 100px;
  }
}

//reuslts.js
.toggleListingTypes {
  position: relative;

  div {
    height: 80px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;

    span {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.listing_container {
  padding: 30px 0 70px 0px;

  h2 {
    padding-bottom: 10px;
  }
}

.starssidebyside {
  padding-bottom: 7px;
  display: flex;
  align-items: center;

  .darkgrey {
    padding-left: 10px;
  }
}

.split_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//profile{
.heartPosition {
  display: flex;
  max-width: 70%;
  align-items: center;
}

.profileHeader {
  min-height: 250px;
  margin-top: 100px;
  background-image: linear-gradient(92.49deg, #0d4461d7 0%, #0078afe7 100%),
  url("/assets/images/filtermain.png");
  background-size: cover;
  background-repeat: no-repeat;

  .goback {
    font-weight: 500;
    padding: 30px 0px 0px 30px;
  }
}

.app_container .hearticon {
  display: none;
}

.profilepicture {
  margin-bottom: -10%;
  background-color: white;
  width: auto;
  max-width: fit-content;
  border-radius: 100%;
  height: 252px;
  display: flex;
  width: auto;
  flex-direction: column;
  max-width: fit-content;
  border-radius: 100%;
  align-items: center;
  justify-content: center;

  img {
    width: 170px;
    margin: 41px;
  }
}

.opacityfade {
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.4s;
  overflow-x: hidden;
}

.filtermain_filter_container {
  position: absolute;
  bottom: 50px;
}

#filterlist[aria-hidden="false"] {
  animation: fade 0.3s;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#profile {
  .content {
    -webkit-transition: opacity 2s ease-in;
    -moz-transition: opacity 2s ease-in;
    -o-transition: opacity 2s ease-in;
    transition: opacity 2s ease-in;
  }

  h1 {
    font-weight: 700;
    font-size: 60px;
    margin: 15px 0px;
  }

  .industry {
    font-size: 25px;
  }

  .padding20 {
    padding-top: 35px;
  }

  .app_container {
    padding: 0 0 50px 0px;
    border-bottom: 1px solid #f4f4f4;
  }

  h1 {
    svg {
      font-size: 40px;
    }
  }

  .hearticon {
    padding-left: 10px;
    width: 35px;
  }
}

.split_between {
  .btn_container {
    display: flex;
  }
}

// #profile {
//   .content {
//     .btn_container {
//       &:last-child {
//         margin-left: 20px;
//       }
//     }
//   }
// }

.inner {
  padding: 0 0 50px 0px;
  border-top: 1px solid $lightGrey;
  border-bottom: 1px solid $lightGrey;

  &:nth-child(odd) {
    border-top: 0px solid white;
  }
}

.verfied {
  margin-bottom: 100px;
}

.claimModal {
  display: flex;
  justify-content: space-between;

  .form_container:nth-child(2) {
    text-align: center;
  }
}

.profile_position {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#profileHeaderBtns {
  display: flex;
  flex-direction: column;
  text-align: center;

  .subline {
    padding-top: 10px;
  }
}

//modal
.modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background: rgb(255, 255, 255);
  overflow: auto;
  outline: none;
  min-height: 300px;
  padding: 20px;
  width: 40%;
  max-width: 550px;
  height: fit-content;
  border: 0px;
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  h4 {
    margin: 20px 0px 10px 0px;
  }

  .splash-spinner {
    margin: 0 auto;
  }
}

.ReactModal__Overlay {
  background-color: #2b2b2bbf !important;
  z-index: 12;
}

.review_modal_inner {
  display: flex;
  justify-content: space-between;
}

.thankyou {
  padding: 80px 0px 60px 0px;
  text-align: center;
}

.textaligncenter {
  text-align: center;
}

#newMessage {
  img {
    margin-bottom: 20px;
  }

  input {
    margin-bottom: 40px;
  }
}

.form_container {
  width: 100%;

  img {
    max-width: 100px;
  }

  input,
  textarea {
    width: 100%;
    height: 4em;
    padding: 15px 20px;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid $lightGrey;
    color: #232323;
    font-size: 14px;
    margin-top: 10px;
  }

  .inputSplit {
    display: flex;
    justify-content: space-between;

    div {
      width: 49%;
    }
  }

  .split_between .fasvg svg {
    font-size: 20px;
    color: #999999;
  }
}

button {
  border: 0px;
  font-size: inherit;
  // color: transparent;
  background-color: transparent;
  padding: 0px;
  cursor: pointer;
}

.drop-down__name:focus {
  outline: none;
}

@media (pointer: coarse) {
  *:focus {
    outline: none;
  }
}

.__react_component_tooltip {
  width: fit-content;
}

.subline {
  color: $darkGrey;
  padding-bottom: 10px;
}

.ReactModal__Content {
  textarea {
    margin-bottom: 20px;
    resize: none;
  }
}

.zeroopacity {
  opacity: 0;
}

.verfied_or_featured {
  .split_between {
    height: 100%;
    position: relative;

    div:last-child {
      margin-top: 50px;
      display: flex;
      align-items: center;
    }

    span {
      padding-left: 10px;
      font-weight: 500;
      font-size: 20px;
      color: white;
    }
  }
}

.cloudexperts_preview {
  color: $darkGrey;
  font-size: 20px;
  padding-bottom: 20px;
}

//ourstory
.supportedBy {
  margin-top: 50px;

  span {
    color: $darkGrey;
    font-size: 16px;
  }

  img {
    margin-top: 15px;
    max-width: 200px;
    display: block;
  }
}

#OurStory {
  grid-auto-rows: 1fr;
  padding-bottom: 100px;

  .main_p {
    margin: 0;
  }

  div:first-child {
    height: 100%;
  }
}

.bubbles {
  background-image: url("/assets/images/appbubbles.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

//listanserviec
#listaservice {
  div img {
    width: 55px;
    padding-bottom: 13px;
  }
}

//terms
.termTitle {
  color: #0076ad;
  font-size: 22px;
  font-weight: 600;
}

//teams
// .team_member_container{
// display:flex;
// }

.inactive {
  z-index: -99999 !important;
  display: none;
}

//heart{
.heartFilledYes {
  color: #ff5757;
  font-size: 25px !important;
  margin-left: 0px;
}

.heartOutline {
  color: $darkGrey;
  font-size: 25px !important;
  margin-left: 15px;
}

.app_container .listingLogo img {
  width: 70px;
}

#platehospo {
  width: 100%;
  object-fit: cover;
  max-height: 500px;
}

.overflowy {
  overflow-y: hidden;
}

//forms
.input-containers {
  input,
  textarea {
    //   display: block;
    //   width: 100%;
    //   height: calc(1.5em + 1.3rem + 2px);
    //   padding: .65rem 1rem;
    //   font-size: 1rem;
    //   font-weight: 400;
    //   color: #3f4254;
    //   border: 0px solid #e4e6ef;
    //   box-shadow: none;
    //   line-height: 1.5;
    //   border-radius: .42rem;
    background-color: #f3f6f9;
  }

  margin-top: 10px;
  margin-bottom: 45px;
}

#imageSpacing {
  height: 80% !important;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: inherit;
  align-items: center;
  // display: flex;
  .swiper-wrapper {
    align-items: center;
  }

  .hearticonWrapper,
  .offertip {
    display: none;
  }

  .swiper-slide-prev {
    opacity: 0;
  }

  .swiper-slide-next {
    opacity: 0.2;

    transform: scale(0.75);
  }

  .mySwiper {
    padding-bottom: 0px;
  }
}

@media (min-width: 1800px) {
  #imageSpacing {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }
}

@media (max-width: 1300px) {
  .stepsIcon {
    width: 135px;
  }

  .company_inner img {
    height: 75px;
    width: auto;
  }

  // h1 {
  //   font-size: 50px !important;
  // }
}

@media (max-width: 1600px) {
  #profile h1 {
    font-size: 45px;
  }
}

//mobile
@media (max-width: 750px) {
  .toggleListingTypes span {
    font-size: 16px !important;
  }
  .content {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #categories_main .profile {
    padding-bottom: 0px;
  }
  #categories_main {
    .gc {
      grid-gap: 0px;
      grid-row-gap: 0px;
      row-gap: 0px;
    }
  }
  .action_swiper_button {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 70%;
    }
  }

  .reviewContainer {
    flex-direction: column;
    align-items: baseline;

    .button {
      padding-left: 0px;
    }
  }
  .modal {
    width: 80%;
    margin: 20px;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;

    button {
      padding: 15px 30px;
    }
  }

  h2 {
    font-size: 23px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 24px !important;
  }
  h6 {
    font-size: 15px !important;
  }

  p {
    font-size: 16px !important;
  }
  .main_p {
    font-size: 16px;
  }
  .stepsIcon {
    width: 136px;
  }
  .circle_arrow {
    transform: rotate(90deg);
    padding: 20px;
  }

  #imageCTAcontainer {
    text-align: center;

    .imageCTA {
      min-height: 300px;
    }
  }
  .company_inner {
    padding: 20px;

    img {
      height: 100px;
      width: auto;
      padding-top: 20px;
    }
  }
  .filterCTA {
    min-height: 550px !important;
  }
  .toolkit_container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .toolkit_container .company {
    width: 80%;
  }
  .company:nth-child(2) {
    margin: 50px 0px;
  }
  #OurStory {
    padding-top: 0px;

    img {
      width: 100%;
    }
  }
  #site_page .filtermain {
    min-height: 650px;
  }
  #listaservice {
    padding-bottom: 0px;
  }
  .profile {
    grid-gap: 0px;
  }
  .profile_position {
    flex-direction: column;
  }
  .profile_mobile {
    text-align: center;
  }
  #profile .content .btn_container:last-child {
    margin: 0px;
    padding-top: 20px;
  }
  .app_container {
    grid-gap: 0px;
  }
  #results_page .opacityfade .split_between {
    flex-direction: column;
  }
  .listing_container:first-child {
    padding-top: 25px;
  }
  .inner {
    padding: 0 0 25px 0px;
  }
  .star {
    fill: #767676 !important;
  }
  #profile {
    .star-ratings {
      margin: 0px;
    }

    .opacityfade {
      padding-top: 120px;
    }

    #profileHeaderBtns .btn_container:last-child {
      margin-top: 15px;
    }

    #profilePictureContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      .profilepicture {
        margin-bottom: -20%;
      }
    }
  }
  .toolCTA .imageCTA {
    min-height: 235px !important;
    border-radius: 0px 0px 5px 5px;
  }
  .toolCTA .imageCTA_inner:nth-child(odd) {
    padding: 30px;
  }
  .toolCTA .imageCTA_inner:nth-child(odd),
  .imageCTA_inner:nth-child(even) {
    grid-column: 1/13;
  }
}

#detailContainer {
  svg {
    padding-right: 10px;
  }
}

@media (max-width: 450px) {
  h4 {
    font-size: 21px !important;
  }
  #profile .industry {
    font-size: 18px;
  }
  #profilePictureContainer {
    .profilepicture {
      margin-bottom: -45% !important;
    }
  }
  #detailContainer {
    svg {
      display: none;
    }

    a {
      word-break: break-all;
    }
  }
}
