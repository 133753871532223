// GRID CSS - BY WILLIAM BARBER

.gc {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 45px;
  row-gap: 45px;
  position: relative;
  // grid-auto-rows: minmax(min-content, max-content);
}

.gc2 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 35px;
  row-gap: 35px;
  position: relative;
  // grid-auto-rows: minmax(min-content, max-content);
}

.gc3 {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-gap: 45px;
  row-gap: 45px;
  position: relative;
  // grid-auto-rows: minmax(min-content, max-content);
}
.no-gap {
  grid-gap: 0;
}

.r-gap-none {
  row-gap: 0;
}

.flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.flex-r {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-middle {
  align-items: center;
}
.flex-c {
  justify-content: center;
}
.flex-s-between {
  justify-content: space-between;
}

/*------------------------------------*\
    #DESKTOP
\*------------------------------------*/
.d-1-1 {
  grid-column: 1 / 1;
}
.d-1-2 {
  grid-column: 1 / 2;
}
.d-1-3 {
  grid-column: 1 / 3;
}
.d-1-4 {
  grid-column: 1 / 4;
}
.d-1-5 {
  grid-column: 1 / 5;
}
.d-1-6 {
  grid-column: 1 / 6;
}
.d-1-7 {
  grid-column: 1 / 7;
}
.d-1-8 {
  grid-column: 1 / 8;
}
.d-1-9 {
  grid-column: 1 / 9;
}
.d-1-10 {
  grid-column: 1 / 10;
}
.d-1-11 {
  grid-column: 1 / 11;
}
.d-1-12 {
  grid-column: 1 / 12;
}
.d-1-13 {
  grid-column: 1 / 13;
}
.d-2-2 {
  grid-column: 2 / 2;
}
.d-2-3 {
  grid-column: 2 / 3;
}
.d-2-4 {
  grid-column: 2 / 4;
}
.d-2-5 {
  grid-column: 2 / 5;
}
.d-2-6 {
  grid-column: 2 / 6;
}
.d-2-7 {
  grid-column: 2 / 7;
}
.d-2-8 {
  grid-column: 2 / 8;
}
.d-2-9 {
  grid-column: 2 / 9;
}
.d-2-10 {
  grid-column: 2 / 10;
}
.d-2-11 {
  grid-column: 2 / 11;
}
.d-2-12 {
  grid-column: 2 / 12;
}
.d-2-13 {
  grid-column: 2 / 13;
}
.d-3-3 {
  grid-column: 3 / 3;
}
.d-3-4 {
  grid-column: 3 / 4;
}
.d-3-5 {
  grid-column: 3 / 5;
}
.d-3-6 {
  grid-column: 3 / 6;
}
.d-3-7 {
  grid-column: 3 / 7;
}
.d-3-8 {
  grid-column: 3 / 8;
}
.d-3-9 {
  grid-column: 3 / 9;
}
.d-3-10 {
  grid-column: 3 / 10;
}
.d-3-11 {
  grid-column: 3 / 11;
}
.d-3-12 {
  grid-column: 3 / 12;
}
.d-3-13 {
  grid-column: 3 / 13;
}
.d-4-4 {
  grid-column: 4 / 4;
}
.d-4-5 {
  grid-column: 4 / 5;
}
.d-4-6 {
  grid-column: 4 / 6;
}
.d-4-7 {
  grid-column: 4 / 7;
}
.d-4-8 {
  grid-column: 4 / 8;
}
.d-4-9 {
  grid-column: 4 / 9;
}
.d-4-10 {
  grid-column: 4 / 10;
}
.d-4-11 {
  grid-column: 4 / 11;
}
.d-4-12 {
  grid-column: 4 / 12;
}
.d-4-13 {
  grid-column: 4 / 13;
}
.d-5-5 {
  grid-column: 5 / 5;
}
.d-5-6 {
  grid-column: 5 / 6;
}
.d-5-7 {
  grid-column: 5 / 7;
}
.d-5-8 {
  grid-column: 5 / 8;
}
.d-5-9 {
  grid-column: 5 / 9;
}
.d-5-10 {
  grid-column: 5 / 10;
}
.d-5-11 {
  grid-column: 5 / 11;
}
.d-5-12 {
  grid-column: 5 / 12;
}
.d-5-13 {
  grid-column: 5 / 13;
}
.d-6-6 {
  grid-column: 6 / 6;
}
.d-6-7 {
  grid-column: 6 / 7;
}
.d-6-8 {
  grid-column: 6 / 8;
}
.d-6-9 {
  grid-column: 6 / 9;
}
.d-6-10 {
  grid-column: 6 / 10;
}
.d-6-11 {
  grid-column: 6 / 11;
}
.d-6-12 {
  grid-column: 6 / 12;
}
.d-6-13 {
  grid-column: 6 / 13;
}
.d-7-7 {
  grid-column: 7 / 7;
}
.d-7-8 {
  grid-column: 7 / 8;
}
.d-7-9 {
  grid-column: 7 / 9;
}
.d-7-10 {
  grid-column: 7 / 10;
}
.d-7-11 {
  grid-column: 7 / 11;
}
.d-7-12 {
  grid-column: 7 / 12;
}
.d-7-13 {
  grid-column: 7 / 13;
}
.d-8-8 {
  grid-column: 8 / 8;
}
.d-8-9 {
  grid-column: 8 / 9;
}
.d-8-10 {
  grid-column: 8 / 10;
}
.d-8-11 {
  grid-column: 8 / 11;
}
.d-8-12 {
  grid-column: 8 / 12;
}
.d-8-13 {
  grid-column: 8 / 13;
}
.d-9-9 {
  grid-column: 9 / 9;
}
.d-9-10 {
  grid-column: 9 / 10;
}
.d-9-11 {
  grid-column: 9 / 11;
}
.d-9-12 {
  grid-column: 9 / 12;
}
.d-9-13 {
  grid-column: 9 / 13;
}
.d-10-10 {
  grid-column: 10 / 10;
}
.d-10-11 {
  grid-column: 10 / 11;
}
.d-10-12 {
  grid-column: 10 / 12;
}
.d-10-13 {
  grid-column: 10 / 13;
}
.d-10-14 {
  grid-column: 10 / 14;
}
.d-11-11 {
  grid-column: 11 / 11;
}
.d-11-12 {
  grid-column: 11 / 12;
}
.d-11-13 {
  grid-column: 11 / 13;
}
.d-12-12 {
  grid-column: 12 / 12;
}
.d-12-13 {
  grid-column: 12 / 13;
}
.d-13-13 {
  grid-column: 13 / 13;
}

.d-hide {
  display: none;
}

.r-1-2 {
  grid-row: 1 / 2;
}
.r-1-3 {
  grid-row: 1 / 3;
}
.r-2-3 {
  grid-row: 2 / 3;
}
.r-1-4 {
  grid-row: 1 / 4;
}
.r-2-5 {
  grid-row: 2 / 5;
}
.r-all {
  grid-row: 1 / -1;
}

.r-1 {
  grid-row: 1;
}
.r-2 {
  grid-row: 2;
}
.r-3 {
  grid-row: 3;
}
.r-4 {
  grid-row: 4;
}
.r-5 {
  grid-row: 5;
}

.span-2 {
  grid-column: span 2;
}
.span-3 {
  grid-column: span 3;
}
.span-4 {
  grid-column: span 4;
}
.span-6 {
  grid-column: span 6;
}

.h-10 {
  height: 10vh;
}
.h-20 {
  height: 20vh;
}
.h-30 {
  height: 30vh;
}
.h-40 {
  height: 40vh;
}
.h-50 {
  height: 50vh;
}
.h-60 {
  height: 60vh;
}
.h-70 {
  height: 70vh;
}
.h-80 {
  height: 80vh;
}
.h-90 {
  height: 90vh;
}
.h-100 {
  height: 100vh;
}

@media (min-width: 1300px) {
  .d-hide {
    display: none;
  }
}
@media (max-width: 1300px) {
  .d-hide {
    display: block;
  }
}
/*------------------------------------*\
    #TABLET
\*------------------------------------*/
@media (max-width: 1300px) {
  .t-1-1 {
    grid-column: 1 / 1;
  }
  .t-1-2 {
    grid-column: 1 / 2;
  }
  .t-1-3 {
    grid-column: 1 / 3;
  }
  .t-1-4 {
    grid-column: 1 / 4;
  }
  .t-1-5 {
    grid-column: 1 / 5;
  }
  .t-1-6 {
    grid-column: 1 / 6;
  }
  .t-1-7 {
    grid-column: 1 / 7;
  }
  .t-1-8 {
    grid-column: 1 / 8;
  }
  .t-1-9 {
    grid-column: 1 / 9;
  }
  .t-1-10 {
    grid-column: 1 / 10;
  }
  .t-1-11 {
    grid-column: 1 / 11;
  }
  .t-1-12 {
    grid-column: 1 / 12;
  }
  .t-1-13 {
    grid-column: 1 / 13;
  }
  .t-2-2 {
    grid-column: 2 / 2;
  }
  .t-2-3 {
    grid-column: 2 / 3;
  }
  .t-2-4 {
    grid-column: 2 / 4;
  }
  .t-2-5 {
    grid-column: 2 / 5;
  }
  .t-2-6 {
    grid-column: 2 / 6;
  }
  .t-2-7 {
    grid-column: 2 / 7;
  }
  .t-2-8 {
    grid-column: 2 / 8;
  }
  .t-2-9 {
    grid-column: 2 / 9;
  }
  .t-2-10 {
    grid-column: 2 / 10;
  }
  .t-2-11 {
    grid-column: 2 / 11;
  }
  .t-2-12 {
    grid-column: 2 / 12;
  }
  .t-2-13 {
    grid-column: 2 / 13;
  }
  .t-3-3 {
    grid-column: 3 / 3;
  }
  .t-3-4 {
    grid-column: 3 / 4;
  }
  .t-3-5 {
    grid-column: 3 / 5;
  }
  .t-3-6 {
    grid-column: 3 / 6;
  }
  .t-3-7 {
    grid-column: 3 / 7;
  }
  .t-3-8 {
    grid-column: 3 / 8;
  }
  .t-3-9 {
    grid-column: 3 / 9;
  }
  .t-3-10 {
    grid-column: 3 / 10;
  }
  .t-3-11 {
    grid-column: 3 / 11;
  }
  .t-3-12 {
    grid-column: 3 / 12;
  }
  .t-3-13 {
    grid-column: 3 / 13;
  }
  .t-4-4 {
    grid-column: 4 / 4;
  }
  .t-4-5 {
    grid-column: 4 / 5;
  }
  .t-4-6 {
    grid-column: 4 / 6;
  }
  .t-4-7 {
    grid-column: 4 / 7;
  }
  .t-4-8 {
    grid-column: 4 / 8;
  }
  .t-4-9 {
    grid-column: 4 / 9;
  }
  .t-4-10 {
    grid-column: 4 / 10;
  }
  .t-4-11 {
    grid-column: 4 / 11;
  }
  .t-4-12 {
    grid-column: 4 / 12;
  }
  .t-4-13 {
    grid-column: 4 / 13;
  }
  .t-5-5 {
    grid-column: 5 / 5;
  }
  .t-5-6 {
    grid-column: 5 / 6;
  }
  .t-5-7 {
    grid-column: 5 / 7;
  }
  .t-5-8 {
    grid-column: 5 / 8;
  }
  .t-5-9 {
    grid-column: 5 / 9;
  }
  .t-5-10 {
    grid-column: 5 / 10;
  }
  .t-5-11 {
    grid-column: 5 / 11;
  }
  .t-5-12 {
    grid-column: 5 / 12;
  }
  .t-5-13 {
    grid-column: 5 / 13;
  }
  .t-6-6 {
    grid-column: 6 / 6;
  }
  .t-6-7 {
    grid-column: 6 / 7;
  }
  .t-6-8 {
    grid-column: 6 / 8;
  }
  .t-6-9 {
    grid-column: 6 / 9;
  }
  .t-6-10 {
    grid-column: 6 / 10;
  }
  .t-6-11 {
    grid-column: 6 / 11;
  }
  .t-6-12 {
    grid-column: 6 / 12;
  }
  .t-6-13 {
    grid-column: 6 / 13;
  }
  .t-7-7 {
    grid-column: 7 / 7;
  }
  .t-7-8 {
    grid-column: 7 / 8;
  }
  .t-7-9 {
    grid-column: 7 / 9;
  }
  .t-7-10 {
    grid-column: 7 / 10;
  }
  .t-7-11 {
    grid-column: 7 / 11;
  }
  .t-7-12 {
    grid-column: 7 / 12;
  }
  .t-7-13 {
    grid-column: 7 / 13;
  }
  .t-8-8 {
    grid-column: 8 / 8;
  }
  .t-8-9 {
    grid-column: 8 / 9;
  }
  .t-8-10 {
    grid-column: 8 / 10;
  }
  .t-8-11 {
    grid-column: 8 / 11;
  }
  .t-8-12 {
    grid-column: 8 / 12;
  }
  .t-8-13 {
    grid-column: 8 / 13;
  }
  .t-9-9 {
    grid-column: 9 / 9;
  }
  .t-9-10 {
    grid-column: 9 / 10;
  }
  .t-9-11 {
    grid-column: 9 / 11;
  }
  .t-9-12 {
    grid-column: 9 / 12;
  }
  .t-9-13 {
    grid-column: 9 / 13;
  }
  .t-10-10 {
    grid-column: 10 / 10;
  }
  .t-10-11 {
    grid-column: 10 / 11;
  }
  .t-10-12 {
    grid-column: 10 / 12;
  }
  .t-10-13 {
    grid-column: 10 / 13;
  }
  .t-11-11 {
    grid-column: 11 / 11;
  }
  .t-11-12 {
    grid-column: 11 / 12;
  }
  .t-11-13 {
    grid-column: 11 / 13;
  }
  .t-12-12 {
    grid-column: 12 / 12;
  }
  .t-12-13 {
    grid-column: 12 / 13;
  }
  .t-13-13 {
    grid-column: 13 / 13;
  }

  .t-span-2 {
    grid-column: span 2;
  }
  .t-span-3 {
    grid-column: span 3;
  }
  .t-span-4 {
    grid-column: span 4;
  }
  .t-span-5 {
    grid-column: span 5;
  }
  .t-span-6 {
    grid-column: span 6;
  }
  .t-span-10 {
    grid-column: span 10;
  }
  .t-span-12 {
    grid-column: span 12;
  }

  .t-r-1 {
    grid-row: 1;
  }
  .t-r-2 {
    grid-row: 2;
  }
  .t-r-3 {
    grid-row: 3;
  }
  .t-r-4 {
    grid-row: 4;
  }
  .t-r-2-4 {
    grid-row: 1 / 4;
  }

  .t-hide {
    display: none;
  }
}

/*------------------------------------*\
    #MOBILE
\*------------------------------------*/
@media (max-width: 750px) {
  .m-1-1 {
    grid-column: 1 / 1;
  }
  .m-1-2 {
    grid-column: 1 / 2;
  }
  .m-1-3 {
    grid-column: 1 / 3;
  }
  .m-1-4 {
    grid-column: 1 / 4;
  }
  .m-1-5 {
    grid-column: 1 / 5;
  }
  .m-1-6 {
    grid-column: 1 / 6;
  }
  .m-1-7 {
    grid-column: 1 / 7;
  }
  .m-1-8 {
    grid-column: 1 / 8;
  }
  .m-1-9 {
    grid-column: 1 / 9;
  }
  .m-1-10 {
    grid-column: 1 / 10;
  }
  .m-1-10 {
    grid-column: 1 / 10;
  }
  .m-1-11 {
    grid-column: 1 / 11;
  }
  .m-1-12 {
    grid-column: 1 / 12;
  }
  .m-1-13 {
    grid-column: 1 / 13;
  }
  .m-2-2 {
    grid-column: 2 / 2;
  }
  .m-2-3 {
    grid-column: 2 / 3;
  }
  .m-2-4 {
    grid-column: 2 / 4;
  }
  .m-2-5 {
    grid-column: 2 / 5;
  }
  .m-2-6 {
    grid-column: 2 / 6;
  }
  .m-2-7 {
    grid-column: 2 / 7;
  }
  .m-2-8 {
    grid-column: 2 / 8;
  }
  .m-2-9 {
    grid-column: 2 / 9;
  }
  .m-2-10 {
    grid-column: 2 / 10;
  }
  .m-2-11 {
    grid-column: 2 / 11;
  }
  .m-2-12 {
    grid-column: 2 / 12;
  }
  .m-2-13 {
    grid-column: 2 / 13;
  }
  .m-3-3 {
    grid-column: 3 / 3;
  }
  .m-3-4 {
    grid-column: 3 / 4;
  }
  .m-3-5 {
    grid-column: 3 / 5;
  }
  .m-3-6 {
    grid-column: 3 / 6;
  }
  .m-3-7 {
    grid-column: 3 / 7;
  }
  .m-3-8 {
    grid-column: 3 / 8;
  }
  .m-3-9 {
    grid-column: 3 / 9;
  }
  .m-3-10 {
    grid-column: 3 / 10;
  }
  .m-3-11 {
    grid-column: 3 / 11;
  }
  .m-3-12 {
    grid-column: 3 / 12;
  }
  .m-3-13 {
    grid-column: 3 / 13;
  }
  .m-4-4 {
    grid-column: 4 / 4;
  }
  .m-4-5 {
    grid-column: 4 / 5;
  }
  .m-4-6 {
    grid-column: 4 / 6;
  }
  .m-4-7 {
    grid-column: 4 / 7;
  }
  .m-4-8 {
    grid-column: 4 / 8;
  }
  .m-4-9 {
    grid-column: 4 / 9;
  }
  .m-4-10 {
    grid-column: 4 / 10;
  }
  .m-4-11 {
    grid-column: 4 / 11;
  }
  .m-4-12 {
    grid-column: 4 / 12;
  }
  .m-4-13 {
    grid-column: 4 / 13;
  }
  .m-5-5 {
    grid-column: 5 / 5;
  }
  .m-5-6 {
    grid-column: 5 / 6;
  }
  .m-5-7 {
    grid-column: 5 / 7;
  }
  .m-5-8 {
    grid-column: 5 / 8;
  }
  .m-5-9 {
    grid-column: 5 / 9;
  }
  .m-5-10 {
    grid-column: 5 / 10;
  }
  .m-5-11 {
    grid-column: 5 / 11;
  }
  .m-5-12 {
    grid-column: 5 / 12;
  }
  .m-5-13 {
    grid-column: 5 / 13;
  }
  .m-6-6 {
    grid-column: 6 / 6;
  }
  .m-6-7 {
    grid-column: 6 / 7;
  }
  .m-6-8 {
    grid-column: 6 / 8;
  }
  .m-6-9 {
    grid-column: 6 / 9;
  }
  .m-6-10 {
    grid-column: 6 / 10;
  }
  .m-6-11 {
    grid-column: 6 / 11;
  }
  .m-6-12 {
    grid-column: 6 / 12;
  }
  .m-6-13 {
    grid-column: 6 / 13;
  }
  .m-7-7 {
    grid-column: 7 / 7;
  }
  .m-7-8 {
    grid-column: 7 / 8;
  }
  .m-7-9 {
    grid-column: 7 / 9;
  }
  .m-7-10 {
    grid-column: 7 / 10;
  }
  .m-7-11 {
    grid-column: 7 / 11;
  }
  .m-7-12 {
    grid-column: 7 / 12;
  }
  .m-7-13 {
    grid-column: 7 / 13;
  }
  .m-8-8 {
    grid-column: 8 / 8;
  }
  .m-8-9 {
    grid-column: 8 / 9;
  }
  .m-8-10 {
    grid-column: 8 / 10;
  }
  .m-8-11 {
    grid-column: 8 / 11;
  }
  .m-8-12 {
    grid-column: 8 / 12;
  }
  .m-8-13 {
    grid-column: 8 / 13;
  }
  .m-9-9 {
    grid-column: 9 / 9;
  }
  .m-9-10 {
    grid-column: 9 / 10;
  }
  .m-9-11 {
    grid-column: 9 / 11;
  }
  .m-9-12 {
    grid-column: 9 / 12;
  }
  .m-9-13 {
    grid-column: 9 / 13;
  }
  .m-10-10 {
    grid-column: 10 / 10;
  }
  .m-10-11 {
    grid-column: 10 / 11;
  }
  .m-10-12 {
    grid-column: 10 / 12;
  }
  .m-10-13 {
    grid-column: 10 / 13;
  }
  .m-11-11 {
    grid-column: 11 / 11;
  }
  .m-11-12 {
    grid-column: 11 / 12;
  }
  .m-11-13 {
    grid-column: 11 / 13;
  }
  .m-12-12 {
    grid-column: 12 / 12;
  }
  .m-12-13 {
    grid-column: 12 / 13;
  }
  .m-13-13 {
    grid-column: 13 / 13;
  }

  .m-r-1 {
    grid-row: 1;
  }
  .m-r-2 {
    grid-row: 2;
  }
  .m-r-3 {
    grid-row: 3;
  }
  .m-r-4 {
    grid-row: 4;
  }
  .m-r-7 {
    grid-row: 7;
  }

  .m-span-5 {
    grid-column: span 5;
  }
  .m-span-6 {
    grid-column: span 6;
  }
  .m-span-10 {
    grid-column: span 10;
  }
  .m-span-12 {
    grid-column: span 12;
  }

  .m-no-gap {
    grid-gap: 0;
  }

  .m-hide {
    display: none !important;
  }
  .d-hide {
    display: block;
  }
}
