.listing {
  padding: 50px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  min-height: 250px;
  display: flex;
  flex-direction: row;
  transition: bottom 0.2s linear;
  bottom: 0px;
  position: relative;

  cursor: pointer;
  &:nth-child(odd) {
    grid-column: 1/7;
  }
  &:nth-child(even) {
    grid-column: 7/13;
  }
  .listingLogo {
    img {
      width: 100px;
      margin-right: 24px;
    }
  }

  p {
    margin: 0px;
  }
  h4 {
    margin: 10px 0 7px 0;
  }
  .main_p {
    margin-bottom: 25px;
  }
  span {
    font-weight: 500;
  }
  &:hover {
    bottom: 5px;
  }
}
.offertip {
  width: auto !important;
  top: 35px;
  right: 60px;
  position: absolute;
  img {
    width: 29px !important;
    &:hover {
      opacity: 0.8;
    }
  }
}
.tooltip {
  z-index: 9999999 !important;
  position: relative;
  background-color: #333333 !important;
  opacity: 1 !important;
  display: flex !important;
  flex-direction: column;
  .tooltiptitle {
    font-size: 15px;
    font-weight: 600;
  }
  span {
    color: white;
    font-weight: 400;
  }
}
.star-ratings {
  margin: 5px 0px 20px 0;
}
.listing_p {
  font-size: 18px;
  line-height: 26px;
}
.pricing_p {
  padding: 20px 0px;
}
.featuredapplogo {
  max-width: 50px !important;
  margin-right: 10px;
}
.app_contaienr {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.listingDetail {
  width: 100%;
  .darkgrey {
    font-weight: 400;
  }
}
.listingWrapper {
  display: flex;
  align-items: center;
  img {
    width: 70px;
    margin-right: 20px;
  }
}
#results_page .hearticonWrapper {
  width: auto !important;
  top: 40px;
  right: 40px;
  position: absolute;
  &:hover {
    .hearticon {
      color: #ff5757;
    }
  }
}
.flag img {
  width: 25px !important;
  padding-top: 5px;
}
@media (max-width: 1200px) {
  .listing {
    &:nth-child(odd) {
      grid-column: 2/12;
    }
    &:nth-child(even) {
      grid-column: 2/12;
    }
    margin: 20px 0px;
    h4 {
      margin: 0px 0 0px 0;
    }
  }
  .listingDetail .darkgrey {
    font-size: 11px;
  }

  .listingWrapper .listing_p:first-child {
    font-size: 12px !important;
  }
}
@media (max-width: 450px) {
  .listing {
    padding: 35px;
  }
}
@media (max-width: 350px) {
  .listingDetail .darkgrey {
    display: none;
  }
}
