.wishlist-menu {
  @media (width >= 700px) {
    position: relative;

    &__anchor {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;

      display: flex;
      justify-content: center;
    }
  }

  &__floating-menu {
    position: absolute;

    @media (width >= 700px) {
      left: unset;
      top: 15px;

      min-height: 200px;
      height: auto;
      max-height: 400px;
      width: 500px;
    }

    width: 100svw;
    left: 0;
    top: 95px;

    overflow-y: auto;

    z-index: 2000;
    transition: all 0.2s ease-out;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    user-select: none;
    opacity: 0;
    border-radius: 5px;
    cursor: default;


    &:before {
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 10px 12px;
      border-color: transparent transparent white transparent;
      left: 47.5%;
      top: -20px;
    }

    &--visible {
      @extend .wishlist-menu__floating-menu;

      opacity: 1;
      background: #FFFFFF;
    }

    &__bar {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      padding: 0;
      margin: 0;
      height: 40px;
      border-bottom: 1px solid #f4f4f4;


      button {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        color: #0076ad;
        cursor: pointer;
        background: #f4f4f4;
        font-family: "Fira Sans", system-ui;

        &.selected {
          color: #333;
          background: #fff;
        }
      }
    }
  }
}