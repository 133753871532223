.action_swiper_button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 9;
  -webkit-transition: opacity 300ms ease;
  -moz-transition: opacity 300ms ease;
  -o-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
  &:hover {
    opacity: 0.7;
  }
}

.swiper-button-disabled {
  opacity: 0.3;
  cursor: default;
}

.swiper-slide {
  opacity: 0;
  -webkit-transition: opacity 300ms ease;
  -moz-transition: opacity 300ms ease;
  -o-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}
.swiper-slide-active {
  opacity: 1;
}
.mySwiper {
  padding-bottom: 50px;
  .activeSlide,
  .swiper-slide-active,
  .swiper-slide-prev,
  .swiper-slide-next {
    opacity: 1;
  }
}

.swiper-container {
  overflow: inherit;
}
