.button {
  box-sizing: inherit;
  border-radius: 5px;
  font-weight: 500;
  border: 0;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in;
  padding: 15px 40px;
  height: 3em;
  display: inline;
  cursor: pointer;
}
.btn_container {
  padding: 15px 0px;
}
@media (max-width: 450px) {
  .button svg {
    display: none;
  }
}
