$darkBlue: #005076;
.searchIcon {
  width: 25px;
  margin-left: 20px;
}

.searchContainer {
  .btn_container {
    min-width: 139px;
  }

  .button .white {
    color: white !important;
  }
}

.SearchOverlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #fffffff2;
  z-index: 9999;
  opacity: 0;
  transition: ease-in-out 200ms;
}

.fadeinsearch {
  opacity: 1;
}

.fadeoutsearch {
  opacity: 0;
  z-index: -1;
  top: 10000000px;
}

.subsearchtext {
  font-size: 14px;
  top: 10px;
  position: relative;
}

.searchWrapper {
  padding: 125px 0px 75px 0px;
}

#SearchResultContainer {
  background-color: #f9f9f9;
}

.searchResults {
  .splash-spinner {
    margin-top: 50px !important;
    display: block;
    margin: 0 auto;
  }

  padding: 75px 0px 75px 0px;

  span {
    font-family: "Fira Sans", sans-serif;
  }

  .found {
    color: black;
    font-size: 30px;
  }

  .query {
    margin-bottom: 30px;

    .bold {
      font-weight: 600;
    }
  }
}

.searchContainer {
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 3px solid $darkBlue;

  form {
    width: 70%;

    input {
      background-color: transparent;
      outline: transparent;
      width: 100%;
      border: 0px;
      color: black;
      font-size: 30px;
      font-family: "Fira Sans", sans-serif;

      &:focus {
        border: 0px !important;
      }
    }
  }
}
