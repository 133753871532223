.menu {
  $darkGeneral: #333333;
  $darkBlue: #005076;
  $lightGrey: #f4f4f4;
  $darkGrey: #999999;

  #menu {
    box-shadow: 0px 10px 40px rgb(0 0 0 / 10%);
    height: 100px;
    position: fixed;
    top: 0;
    color: white;
    position: fixed;
    width: 100vw;
    z-index: 11;
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;

    #filter_dropDown {
      padding: 0px;
      padding-right: 10px;
      background-color: #f4f4f4;
      height: 30px;
      width: 200px;
      margin-left: 40px;
    }
  }

  //#main_categoryDropList {
  //  overflow: hidden;
  //}

  #main_categoryDropList[aria-hidden="false"] {
    animation: fadeIn 0.3s;
  }

  // #filterlist[aria-hidden="false"] {
  //   animation: fadeIn 0.3s;
  // }
  @keyframes fadeIn {
    from {
      opacity: 0;
      margin-top: 10px;
    }

    to {
      opacity: 1;
      margin-top: 0px;
    }
  }

  .aboveMenu {
    position: relative;
    z-index: 10000;
  }

  .headerbar-container {
    min-height: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #main_categoryDrop {
    overflow: hidden;
    overflow-y: hidden;
    padding: 3px 12px;
    height: auto !important;
    background-color: #f4f4f4;
    // box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  }

  .header-logo {
    height: 70px;
  }

  .header-signup {
    justify-content: flex-end !important;
    margin-left: 20px;

    .header-signup {
      background-color: rgba(0, 0, 0, 0.3);
      color: white !important;
    }
  }

  .main_categoryDropList {
    overflow: hidden;
  }

  .header-actions {
    box-sizing: inherit;
    border-radius: 5px;
    border: 0px;
    display: flex;
    align-items: center;
    transition: ease-in 0.3s;
    padding: 0px 20px 0px 15px;
    height: 2.5em;

    &:hover {
      opacity: 0.8;
      cursor: pointer;

      .pop_up_container {
        opacity: 1;
      }
    }

    span {
      padding-left: 5px;
      font-size: 16px;
      font-weight: 600;
      color: white;
    }

    svg {
      font-size: 12px;
    }
  }

  .scrolled {
    -webkit-transform: translateY(calc(-100% - 26px));
    transform: translateY(calc(-100% - 26px));
  }

  .menu_background_white {
    background-color: white;

    span {
      color: $darkGeneral;
    }

    .header-signup .header-signup {
      background-color: $darkBlue;

      span {
        color: white;
      }
    }
  }

  .pop_up_container {
    display: flex;
    opacity: 1 !important;
    justify-content: center;
  }

  #profileDropdown {
    min-height: 200px;
    height: auto;
    margin-right: 0 !important;
    z-index: 1000;
    opacity: 0;

    &:before {
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 10px 12px;
      border-color: transparent transparent white transparent;
      left: 47.5%;
      top: -20px;
    }

    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }

  .popupmodal {
    position: absolute;
    top: 70px;
    width: 500px;
    background: #fff;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 2;
    flex-direction: column;
    color: #000;
    cursor: default;
    margin-top: 5px;
  }

  // .inactive{
  //     display: none;
  // }
  .active {
    top: 70px;
    opacity: 1 !important;
  }

  .toggleListingTypes_wishlist {
    div {
      height: 40px;
      border-radius: 5px;
      justify-content: center;
      flex-direction: column;
      display: flex;

      span {
        font-size: 14px;
        font-weight: 500;
      }
    }

    .toolbox_item {
      height: 80px;
      border-radius: 0px;
      border-bottom: 1px solid $lightGrey;

      h6 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }

      span {
        color: $darkGeneral;
        padding-bottom: 10px;
        font-weight: 400;
        font-size: 13px;
        padding: 0px 0px 5px 0px;
      }

      img {
        width: 57px;
        margin: 0 auto;
      }

      &:last-child {
        border-bottom: 0px solid white;
      }

      &:first-child {
        -webkit-animation: move 0.3s ease-out;
        animation: move 0.3s ease-out;
      }
    }
  }

  #toolboxItems {
    max-height: 400px;
    overflow-y: auto;
  }

  #toolbox {
    .toggleListingTypes_wishlist {
      &:first-child {
        border-bottom: 1px solid $lightGrey;

        div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .subtle {
            font-size: 11px;
            color: $darkGrey;
          }
        }
      }
    }
  }

  .remove_toolkit {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;

    svg {
      padding: 0px 50px;
      font-size: 15px;
      color: $darkGrey;

      &:hover {
        cursor: pointer;
      }
    }
  }

  @-webkit-keyframes move {
    0% {
      margin-top: 40px;
    }
    100% {
      margin-top: 0px;
    }
  }

  @keyframes move {
    0% {
      margin-top: 80px;
    }
    100% {
      margin-top: 0px;
    }
  }

  #landingDropdownLoggedin {
    width: 150px;
    margin-right: 0px !important;
    z-index: 1000;
    position: absolute;
    top: 60px !important;
    right: 30px;

    a {
      color: black !important;
    }
  }

  .menuppopupmodal {
    width: 200px;

    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 2;
    flex-direction: column;
    color: black;
    cursor: default;
    margin-top: 25px;
    margin-right: 20px;

    a span {
      color: black !important;
    }

    div {
      cursor: pointer;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 27px 0px 27px 15px;
      border-radius: 5px;

      &:hover {
        background-color: #f9f9f9;
      }
    }
  }

  .header-actions span {
    font-weight: 500;
  }

  .inactiveMenu {
    display: none !important;
  }

  .fa-heart {
    font-size: 20px !important;
  }

  .heartFilledWhite {
    color: white;
  }

  .heartFilledBlack {
    color: black;
  }

  .fa-bars,
  .fa-times {
    font-size: 22px;
  }

  .center_toolbox {
    align-items: center !important;
    display: flex !important;

    svg {
      margin-top: 50px;
    }

    overflow-y: hidden !important;
  }

  @media (max-width: 995px) {
    .header-actions {
      padding: 0px 10px 0px 5px !important;
    }
  }

  @media (max-width: 750px) {
    .header-actions {
      padding: 0px;
    }
    #profileDropdown:before {
      left: 60.5% !important;
    }
    #profileDropdown {
      width: 100%;
      left: 0;
    }
  }

  .mobile-menu {
    @media (width >= 700px) {
      display: none;
    }
  }

}