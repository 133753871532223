.wishlist-button {
  button {
    cursor: pointer;
    margin-left: 15px;
    color: var(--dark-grey, #656565);

    svg {
      font-size: 25px;
    }
  }

  &--active {
    @extend .wishlist-button;

    button {
      svg {
        color: var(--light-red, #ff5757);
      }
    }
  }
}

.listing_new .wishlist-button {
  position: absolute;
  top: 39px;
  right: 40px;
  z-index: 10;
}

.listing_new.blue_listing .wishlist-button {
  button {
    svg.outline {
      color: #fff;
    }
  }
}