$breakpoints: (
        mobile: 599px, // Handles screens from 0 to 599px
        tablet: 600px,
        desktop: 900px,
        large: 1200px
);

@mixin respond-to($breakpoint) {
  @each $key, $value in $breakpoints {
    @if $breakpoint == $key {
      @if $breakpoint == mobile {
        @media (width <= $value) {
          @content;
        }
      } @else {
        @media (width >= $value) {
          @content;
        }
      }
    }
  }
}