@import "@scss/mixins";

.search-result-item {
  padding: 40px;
  min-height: 75px;
  margin: 25px 0;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  transition: bottom 0.2s linear;
  bottom: 0;
  position: relative;

  justify-content: space-between;

  .search-result-item__inner-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &:hover {
    bottom: 5px;
    cursor: pointer;
  }

  &__logo {
    @include respond-to(mobile) {
      display: none;
    }

    img {
      width: 75px;
    }
  }

  &__info {
    @include respond-to(tablet) {
      padding-left: 20px;
    }

    .listingType {
      font-weight: 600;
      font-size: 14px;
      margin: 0 5px 0 0;
    }

    .listingSubLabel {
      opacity: 0.8;
      font-size: 13px;
      margin: 0 0 0 5px;
    }

    .listingTitle {
      font-size: 20px;
      margin: 5px 0;
      font-weight: 600;
      display: block;
    }

    .listingReview {
      span {
        font-size: 14px;
        color: #656565;
      }
    }
  }

  &__arrow {
    display: flex;
    flex-direction: row;
    align-items: center;

    .fa-long-arrow-alt-right {
      font-size: 24px;
      color: var(--dark-blue);
    }
  }
}