$regBlue: #0076ad;
$lightGrey: #f4f4f4;
$darkGeneral: #333333;
$border: #ddd;
.filter_drop-down {
  display: inline-block;
  position: relative;
  z-index: 9;
}

.filter_drop-down__button {
  background-color: white;
  display: inline-block;
  line-height: 40px;
  padding: 5px 15px;
  text-align: left;
  border-radius: 4px;
  cursor: pointer;
  min-width: 200px;
  width: auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  button {
    height: 20px;
    width: 100%;
    position: relative;
    display: block;
    text-align: left;

    outline: none;
    &:hover {
      opacity: 0.7;
    }
  }
}

#filter_dropDown {
  svg {
    color: $regBlue;
    font-size: 10px;
  }
  &:focus-within {
    background-color: $lightGrey;
  }
}
.filter_drop-down__name {
  font-size: 15px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
}

.filter_drop-down__icon {
  width: 18px;
  vertical-align: middle;
  margin-left: 14px;
  height: 18px;
  border-radius: 50%;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}

#ullist {
  outline: none;
  border: 1px solid #ddd;
}
.filter_drop-down__menu-box {
  max-height: 400px;
  margin-top: 3px;
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  border-radius: 4px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  visibility: visible;
  opacity: 1;
  width: 99%;
  background-color: white;
  &:has(#ullist[aria-hidden="false"]) {
    visibility: visible;
    opacity: 1;
    width: 99%;
    background-color: white;
  }
}

.filter_drop-down__menu {
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  list-style: none;
  &:focus {
    outline: none;
    // border: 1px solid #ddd;
  }
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none !important;
  }
}

.filter_drop-down__menu-box:after {
  content: "";
  background-color: transparent;
}

.filterul {
  color: $regBlue !important;
  font-size: 14px;
  font-weight: 600;
  &:first-letter {
    text-transform: uppercase;
  }
}
.filter_drop-down__item {
  font-size: 13px;
  padding: 10px 18px;
  text-align: left;
  font-weight: 600;
  color: $regBlue;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #efefef;
  &[aria-selected="true"] {
    background-color: $lightGrey;
  }
  &:hover {
    background-color: $lightGrey;
  }
}

.filter_drop-down__item-icon {
  width: 15px;
  height: 15px;

  position: absolute;
  right: 0px;
  fill: $regBlue;
}

// .drop-down__item:last-of-type{
//   border-bottom: 0;
// }

.filter_drop-down--active .filter_drop-down__menu-box {
  visibility: visible;
  opacity: 1;
  width: 99%;
  background-color: white;
}

// .drop-down__item:before{
//   content:'';
//   position: absolute;
// width: 3px;
// height: 28px;
// background-color: #3d6def;
// left: -13px;
// top: 50%;
// transform: translateY(-50%);
//   display:none;
// }

.filter_drop-down__item:hover:before {
  display: block;
}
@media (max-width: 750px) {
  .filter_drop-down__button {
    min-width: 170px;
  }
}
