#offer {
  padding-top: 60px;
  .offerwrapper {
    border-radius: 8px;
    background-color: #005076;
    color: white;
    padding: 20px;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;
  }
  img {
    height: 30px;
    padding-right: 15px;
  }
  .offerinfo {
    display: flex;
    flex-direction: row;
    h5 {
      font-size: 16px;
    }
    span {
      font-size: 14px;
    }
  }
  h5 {
    margin: 5px 0px;
    color: white;
  }
  span {
    color: white;
  }
  .button span {
    white-space: nowrap;
  }
}
