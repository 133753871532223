.testimonail_container{

    padding:50px 50px 20px 50px;
    .italics{
        font-size:40px;
        padding-top:50px;
        padding-bottom:50px;
        display: block;
        padding-right: 7%;
    }
}
.testimonial_header{
    margin:75px 0 50px 0px;
}
#main{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

#gglogo{
    width: 15%;
    min-width: 120px;
}
#plate{
    display: flex;
    flex-direction: row-reverse;

}
.bagel_owner{
    display: flex;
    img{
        width: 8%;
        min-width: 50px;
        padding-right: 30px;
    }
    .inline-block{
        display: flex;
        flex-direction: column;
    }
    .strong_span{
        font-size:19px;
    }
    .owner{
        font-size: 24px;
        margin:0px 0px 5px 0px;
    }
}

.item_toolbox{
   
    h3{
        font-size: 24px;
        margin:0px 0px 20px 0px;
    }
    &:nth-child(2){
        padding: 50px 0px;
    }
&:first-child{
    
        padding: 50px 50px 50px 50px;
    
}
    &:last-child{
        padding: 50px 50px 50px 50px;
    }
    .toolbox_app_container{
        &:nth-child(n+1){
        margin-top: 20px;}
    }
}
.toolbox_app_container{
    display: flex;
    box-shadow: 0 0 30px rgba(0,0,0,.1);
    align-items: center;
    background-color:white;
    border-radius: 5px;
    padding: 20px;
img{
    width:50px;
    margin-right: 20px;
}
h6{
    font-size: 22px;
    margin:0;
}
p{
    margin:5px 0px;
}
}
.app_container_testi{
    display:flex;
    .item_toolbox{
        width:30%
    }
}
#testimonial{
    margin-bottom:50px;
}
@media(max-width: 1000px){
    .app_container_testi{
        flex-direction: column;
    }
    .app_container_testi{
        flex-direction: column;
    }
    .app_container_testi .item_toolbox{
        width: auto;
    }
    .item_toolbox:nth-child(1) {
        padding: 50px 25px 0px 25px;
     
    }
    .item_toolbox:nth-child(2) {
        padding: 55px 25px 55px 25px;
       
    }
    .item_toolbox:nth-child(3) {
        padding: 0px 25px 75px 25px;
  
    }
}
@media(max-width: 750px){
    .testimonail_container{
        padding:50px 25px 00px 25px;
        margin:0px 25px 00px 25px;
        #platehospo{
            width: 100%;
        }
    }
    .testimonail_container .italics{
        padding-right: 0px;
        font-size: 22px;
        text-align: center;
    }
    #testimonial{
        margin-bottom: 25px;
    }
    #testimonial .button{
        padding: 0px;

    }
    #discover{
        padding: 25px 50px;
        margin: 0 25px;
    }
    #main{
        align-items: center;
    }
    .bagel_owner img{
        min-width: 75px;
    }
    .sub_span{
        font-size: 18px;
    }

    .item_toolbox:nth-child(1) {

        margin:0px 25px 0px 25px;
    }
    .item_toolbox:nth-child(2) {

        margin:0px 25px 0px 25px;
    }
    .item_toolbox:nth-child(3) {
     
        margin:0px 25px 0px 25px;
    }
    .testimonial_header{
        margin: 15px 0 25px 0px;
    }

}