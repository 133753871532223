.listing_new {
  padding: 30px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  transition: bottom 0.2s linear;
  bottom: 0px;
  position: relative;

  cursor: pointer;
  &:nth-child(3n + 1) {
    grid-column: 1/5;
  }
  &:nth-child(3n + 2) {
    grid-column: 5/9;
  }

  &:nth-child(3n + 3) {
    grid-column: 9/13;
  }
  .listingLogo_new {
    min-height: 85px;
    max-height: 85px;
    display: flex;
    align-items: center;

    img {
      width: 75px;
      margin-right: 24px;
    }
  }

  p {
    margin: 0px;
  }
  h4 {
    margin: 10px 0 7px 0;
  }
  .main_p {
    margin-bottom: 25px;
  }
  span {
    font-weight: 500;
  }
  &:hover {
    bottom: 5px;
  }
}

.listing_new_services {
  padding: 30px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  min-height: 250px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  transition: bottom 0.2s linear;
  bottom: 0px;
  position: relative;
  align-items: center;
  cursor: pointer;
  &:nth-child(3n + 1) {
    grid-column: 1/5;
  }
  &:nth-child(3n + 2) {
    grid-column: 5/9;
  }

  &:nth-child(3n + 3) {
    grid-column: 9/13;
  }
  .listingDetail_new {
    width: 70%;
  }
  .listingLogo_new {
    min-height: 85px;
    max-height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50%;
      max-width: 249px;
      min-width: 150px;
    }
  }

  p {
    margin: 0px;
  }
  h4 {
    margin: 10px 0 7px 0;
  }
  .main_p {
    margin-bottom: 25px;
  }
  span {
    font-weight: 500;
  }
  &:hover {
    bottom: 5px;
  }
}
.offertip_new {
  width: auto !important;
  top: 20px;
  right: 35px;
  position: absolute;
  img {
    width: 25px !important;
    &:hover {
      opacity: 0.8;
    }
  }
}
.tooltip_new {
  z-index: 9999999 !important;
  position: relative;
  background-color: #333333 !important;
  opacity: 1 !important;
  display: flex !important;
  flex-direction: column;
  .tooltiptitle {
    font-size: 15px;
    font-weight: 600;
  }
  span {
    color: white;
    font-weight: 400;
  }
}

.listing_p_new {
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 20px;
}

.listingDetail_new {
  width: 100%;
  padding-left: 10px;
  padding-top: 10px;
  .darkgrey {
    font-weight: 400;
  }
}
.listingWrapper_new {
  display: flex;
  align-items: center;
  img {
    width: 70px;
    margin-right: 20px;
  }
}
#results_page .hearticonWrapper {
  width: auto !important;
  top: 40px;
  right: 60px;
  position: absolute;
  &:hover {
    .hearticon {
      color: #ff5757;
    }
  }
}

.moveflag {
  right: 103px !important;
}
.blue_listing {
  background-color: #0076ad;
  p,
  span,
  h4,
  .main_p,
  .blue,
  .heartOutline {
    color: white !important;
  }
  .listingLogo_new {
    padding-top: 15px;
  }
  .listingLogo_new img {
    padding: 15px;
    /* margin: 9px; */
    background-color: white;
    border-radius: 68px;
  }
  h4 {
    padding-top: 20px;
  }
}

@media (max-width: 1200px) {
  .listing_new_services .listingLogo_new img {
    width: 130px;
    margin-right: 24px;
    min-width: auto;
  }
  .listing {
    &:nth-child(odd) {
      grid-column: 2/12;
    }
    &:nth-child(even) {
      grid-column: 2/12;
    }
    margin: 20px 0px;
    h4 {
      margin: 0px 0 0px 0;
    }
  }
  .listing_new {
    &:nth-child(odd) {
      grid-column: 1/7;
    }
    &:nth-child(even) {
      grid-column: 7/13;
    }
    // margin: 20px 0px;
    h4 {
      margin: 0px 0 0px 0;
    }
    .listingLogo_new img {
      position: absolute;
      top: 30px;
    }
  }
  .listingDetail .darkgrey {
    font-size: 11px;
  }

  .listingWrapper .listing_p:first-child {
    font-size: 12px !important;
  }
}
// .typeform-share button {
//   display: none;
// }
@media (max-width: 750px) {
  .listing_new_services {
    justify-content: normal;
    align-items: baseline;
    flex-direction: column;
    .listingLogo_new img {
      width: 75px;
      margin-right: 24px;
      min-width: auto;
    }
    .listingDetail_new {
      width: 100%;
    }
    // .blue {
    //   display: none;
    // }
  }

  .listing_new {
    &:nth-child(odd) {
      grid-column: 2/12;
    }
    &:nth-child(even) {
      grid-column: 2/12;
    }
    margin: 20px 0px;
  }
}
@media (max-width: 450px) {
  .listing {
    padding: 35px;
  }
}
@media (max-width: 350px) {
  .listingDetail .darkgrey {
    display: none;
  }
}
