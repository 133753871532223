:root {
  --light-blue: #7bd5ff;
  --blue: #0076ad;
  --dark-blue: #005076;

  --light-grey: #f4f4f4;
  --super-light-grey: #f9f9f9;
  --grey: #e1e1e1;

  --green: #5eb22e;

  --dark-general: #333333;
  --dark-grey: #656565;

  --red: #ce2759;
  --light-red: #ff5757;
}