.footer-logo {
  width: 170px;
}
.footer-links {
  display: flex;
  flex-direction: column;
  span {
    padding: 10px 0px;
    font-weight: 600;
  }
}

.borderRight {
  border-right: 1px solid #dddddd;
}
.image {
  .footer-links {
    padding-top: 100px;
  }
}

.footer-gov {
  width: 180px;
  min-width: 200px;
}
.individual {
  min-width: 140px;
  max-width: 175px !important;

  padding-top: 10px;
}
.dboost {
  max-width: 150px !important;
}
#footer {
  background-color: #f9f9f9;
  .gc {
    &:last-child {
      .footer-links {
        margin: 40px 0px 0px 0px;
      }
    }
  }
}
.supportFooter {
  color: #656565;
  font-weight: 400 !important;
  font-size: 15px;
  padding: 0px !important;
}
@media (min-width: 750px) {
  .thide {
    display: none;
  }
}
@media (min-width: 1250px) {
  .deskhide {
    display: none;
  }
}
@media (max-width: 1250px) {
  .footer-logo {
    width: 116px;
  }
  .footer-gov {
    &:first-child {
      padding-top: 20px;
    }
  }
}
@media (max-width: 750px) {
  .twologos {
    padding-bottom: 26px;
    border-bottom: 1px solid #dddddd;

    // img{
    //     // width: 45%;
    //     height: fit-content;
    // }
  }
  .dboost {
    width: 140px;
    padding-top: 20px;
  }
  .deskhide.thide.m-2-12.image.borderRight {
    padding-top: 50px;
    .footer-logo {
      width: 166px;
    }
  }
  #footer .gc:last-child .footer-links {
    margin: 20px 0px 0px 0px;
  }

  #footer .sub_span {
    font-size: 14px;
  }
  .footer-gov {
    max-width: 230px;
  }
  .borderRight {
    border-right: white !important;
  }
}
