.reviews:nth-child(odd) {
    grid-column: 1/7;
  }
  .reviews:nth-child(even) {
    grid-column: 7/13;
  }
  .reviews{
    padding: 20px;
    border-radius: 5px;
    background-color: #F4F4F4;
    display: flex;
    min-height: 100px;
    display: flex;
    flex-direction: row;
   
  }
  .review_contaner{
    display: flex;
    flex-direction: column;
  }
  .qoute{
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
  }

  @media(max-width: 750px){
    .reviews:nth-child(odd) {
      grid-column: 1/13;
    }
    .reviews:nth-child(even) {
      grid-column: 1/13;
    }
    .reviewContainer{
      grid-gap: 0px !important;
    }
    .reviews{
      margin: 10px 0px;
    }
  }