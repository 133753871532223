$lightGrey: #f4f4f4;
$themeRed: #ce2759;
$darkGrey: #656565;
.drop-down {
  display: inline-block;
  position: relative;
}

.drop-down__button:focus-within {
  background-color: $lightGrey;
}

.controller_list {
  &:focus {
    outline: none;
  }
}

.landing_button {
  padding: 0 18px;
  text-align: left;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;

  .button {
    padding: 21px 68px;
  }

  &:hover > .background_red {
    background-color: rgb(173, 20, 66);
  }
}

.drop-down__button {
  background: white;
  display: inline-block;
  line-height: 40px;
  padding: 0 18px;
  text-align: left;
  border-radius: 4px;
  cursor: pointer;
  height: 60px;
  width: 250px;
  align-items: center;
  display: flex;
  min-width: 255px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  // &:focus{
  //   outline: none;
  // }
  svg {
    color: $darkGrey;
  }
}

.drop-down__button[aria-expanded="true"] {
  background-color: $lightGrey;
}

.drop-down__name,
.controller_list {
  font-size: 18px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 400;
  color: $darkGrey;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
}

.drop-down__icon {
  width: 18px;
  color: rgba(0, 0, 0, 0.1);
  vertical-align: middle;
  margin-left: 14px;
  height: 18px;
  border-radius: 50%;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}

.drop-down__menu-box {
  overflow: hidden;
  overflow-y: auto;
  max-height: 300px;
  position: absolute;
  width: 100%;
  z-index: 100000;
  left: 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  margin-top: 5px;

  //ahead of my time ;)
  // &:has(> #categories_ul) {
  //   overflow: hidden;
  // }
  //#categories_ul {
  //  overflow: hidden;
  //}
  #categories_ul[aria-hidden="false"] {
    animation: fadeIn 0.3s;
  }

  #industries_ul,
  #categories_ul {
    max-height: 300px;
  }

  #industries_ul[aria-hidden="false"] {
    animation: fadeIn 0.3s;
  }

  a {
    &:visited {
      color: $themeRed;
    }

    & :active {
      color: $themeRed;
    }

    & :focus {
      color: $themeRed;
    }
  }
}

.drop-down__menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.drop-down__menu-box:after {
  content: "";
  background-color: transparent;
}

.controller_list {
  width: 100%;
  display: block;
  height: 100%;
  cursor: pointer;
  text-align: left;
  // &:focus{
  //   opacity:0.7;
  //   outline:none
  // }
}

.controller_UL {
  outline: none;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

.drop-down__item {
  font-size: 18px;
  padding: 20px 18px;
  text-align: left;
  font-weight: 700;
  color: $themeRed;
  cursor: pointer;
  position: relative;

  &:visited {
    color: $themeRed;
  }

  & :active {
    color: $themeRed;
  }

  & :focus {
    color: $themeRed;
  }

  &:hover {
    border-radius: 5px;
    background-color: $lightGrey;
  }
}

.drop-down__item[aria-selected="true"] {
  border-radius: 5px;
  background-color: $lightGrey;
}

.drop-down__item-icon {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0px;
  fill: #8995b6;
}

// .drop-down__item:last-of-type{
//   border-bottom: 0;
// }

.drop-down--active .drop-down__menu-box {
  visibility: visible;
  opacity: 1;
}

// .drop-down__item:before{
//   content:'';
//   position: absolute;
// width: 3px;
// height: 28px;
// background-color: #3d6def;
// left: -13px;
// top: 50%;
// transform: translateY(-50%);
//   display:none;
// }

.drop-down__item:hover:before {
  display: block;
}
