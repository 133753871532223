.team_member{
    width: 22%;
    margin-right: 3%;
    display: inline-block;

    img{
        width: 100%;
        object-fit: cover;
        height: 100%;
        width: 70%;
        min-width: 175px;
        height: 175px;
        margin-bottom: 20px;
    }
    h5{
        font-size: 22px;
        margin: 0px;
    }
    p{
        font-size: 19px;
        line-height: 28px;
        color: #656565;
    }
    span{
        font-size: 22px;
    }
}
@media(max-width: 750px){
    .team_member{
        width: 47%;
    }
 
}
@media(max-width: 450px){
    .team_member{
        width: 80%;
    }
    .team_member_container,.team_member{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
