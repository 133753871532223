.home-card {
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  min-height: 250px;
  position: relative;
  cursor: pointer;

  transition: transform 0.2s linear;

  &:hover {
    transform: translateY(-5px);
  }


  &--regular {
    @extend .home-card;

    &:nth-child(odd) {
      grid-column: 1/7;
    }

    &:nth-child(even) {
      grid-column: 7/13;
    }

    @media (max-width: 1200px) {
      margin: 20px 0;
      &:nth-child(odd) {
        grid-column: 2/12;
      }
      &:nth-child(even) {
        grid-column: 2/12;
      }
    }
  }

  &--regular--grey {
    @extend .home-card--regular;
    box-shadow: none;
    padding: 50px;
    text-align: center;
    justify-content: center;
    background-color: var(--grey);

    .home-card__content {
      > div {
        padding: 0;
      }
    }

    .home-card__title, .home-card__subtitle {
      margin: 0;
      font-weight: 700;
    }

    .home-card__button {
      padding: 15px 0;

      a {
        background-color: var(--blue);
        box-sizing: inherit;
        border-radius: 5px;
        font-weight: 500;
        border: 0;
        align-items: center;
        transition: 0.3s ease-in;
        padding: 15px 40px;
        display: inline-flex;
        cursor: pointer;

        span {
          font-weight: 500;
          color: #fff;
        }
      }


    }
  }
}

.home-card__content {
  // legacy: .listingDetail
  > div {
    // legacy: .listingWrapper
    padding: 30px;

    > div {
      // legacy (no class)
      .home-card__logo {
        width: auto;
        height: auto;
        max-width: 173px;
        max-height: 42px;
        margin-right: 20px;
      }

      .home-card__body {
        padding: 20px 0;
      }
    }
  }
}

.home-card__title, .home-card__subtitle {
  margin: 10px 0 7px 0;
  font-weight: 500;
  font-size: 28px;

  span {
    color: var(--red, '#333');
  }
}

.home-card__subtitle {
  color: var(--blue);
}

.home-card__body {
  margin: 0;
  padding: 20px 0;
  font-size: 18px;
  line-height: 26px;
}

.home-card__button {
  padding: 15px 0;

  span {
    font-weight: 500;
    color: var(--blue);
  }
}