.gradient_blue_cta{
    background: linear-gradient(92.49deg, #0D4361 0%, #0077AF 100%);
}
#service_cta{
    margin-top: 100px;
    .servicecta_container{
        padding: 50px 0px 75px 0px;
    }
}

@media(max-width: 750px){
    #service_cta .servicecta_container{
        padding: 25px 0px 25px 0px;
     
    }
    #service_cta{
        .btn_container{
            margin-left:0px !important;
        }
    }
}