.wishlist-menu__item {
  min-height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
  --animation-time: 0.5s;

  @media (width >= 700px) {
    animation: listingEntrance var(--animation-time, 1s) ease forwards;
  }

  &.dissolve {
    animation: listingDissolve var(--animation-time, 1s) ease forwards;

    & + .wishlist-menu__item {
      transition: transform var(--animation-time, 1s);
      transform: translateY(-80px);
    }
  }


  &__logo {
    display: flex;
    justify-content: center;
    width: 125px;
    flex-shrink: 0;
    min-width: 125px;

    img {
      height: 57px;
      object-fit: contain;
      width: 100%;
      max-width: 100px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .label {
      --rem: 16;
      --swiper-theme-color: #007aff;
      -webkit-font-smoothing: antialiased;
      cursor: default;
      font-family: "Fira Sans", sans-serif;
      color: #333333;
      font-weight: 400;
      font-size: 13px;
      padding: 0px 0px 5px 0px;
    }

    a {
      --rem: 16;
      --swiper-theme-color: #007aff;
      -webkit-font-smoothing: antialiased;
      font-family: "Fira Sans", sans-serif;
      text-decoration: none;
      color: #0076ad;
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }
  }

  &__remove {
    button {
      cursor: pointer;

      svg {
        padding: 0px 50px;
        font-size: 15px;
        color: #999999;
        width: 0.6875em;
      }
    }

    &.deleting {
      animation: spin 1s linear infinite;
    }
  }
}

@keyframes listingDissolve {
  0% {
    filter: blur(0);
    opacity: 1;
  }

  50% {
    opacity: 0.9;
  }

  100% {
    filter: blur(50px);
    opacity: 0;
  }
}

@keyframes listingEntrance {
  from {
    transform: translateY(25px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}