.gradient_blue_cta{
    background: linear-gradient(92.49deg, #0D4361 0%, #0077AF 100%);
}
#small_CTA{
    display:flex;
    margin: 20px;
    justify-content: center;
    a{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    svg{
        color:white;
        font-size: 22px;
    }
}
@media(max-width: 1300px){
    #small_CTA {
        flex-direction: column;
        align-items: center;
        p:first-child{
            margin-bottom: 0;
        }
        p:last-child{
            margin-top: 0;
        }
    
}
}
@media(max-width: 750px){
}
