.filtermain_old {
  padding-top: 50px;
  min-height: 500px;
  background-image: linear-gradient(
      94.5deg,
      rgba(0, 80, 118, 0.3) 0%,
      rgba(206, 39, 89, 0.3) 100%
    ),
    url("../../assets/images/filtermain.png");
  background-size: cover;
  background-repeat: no-repeat;
  .gov_logo {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 40px;
    display: flex;
    flex-direction: column;
    img {
      width: 25vw;
      max-width: 150px;
      min-width: 120px;
      padding-top: 5px;
    }
    span {
      font-size: 13px;
    }
  }
  h1 {
    font-weight: 700;
    font-size: 60px;
    margin-top: 15px;
    margin-bottom: 20px;
    color: white;
  }
  span {
    font-size: 20px;
    color: white;
    line-height: initial;
    margin: 0;
    font-weight: 500;
  }
}
